import amount from "./modules/amount";
import dropdown from "./modules/dropdown";
import modal from "./modules/modal";
import openSearch from "./modules/openSearch";
import pureSlider from "./modules/slider";
import date from "./modules/the-datepicker.min";
import timer from "./modules/timer";
import scroll from './modules/scrollTop';
import validation from "./modules/validation";
import mask from "./modules/phoneMask";
date();
document.addEventListener('DOMContentLoaded', () => {
    pureSlider('.product-imgs__wrapper', 'imgs', 'classic');
    pureSlider('.main-slider__slides', 'dots', 'opacity');
    pureSlider('.ideal-wrapper', 'multiple', 'classic');
    pureSlider('.companies-wrapper', 'multiple', 'classic');

    validation();
    amount();
    openSearch();
    modal();
    dropdown();
    scroll();
    mask('.phone-input');
    const adv = document.querySelector('.advantages__slides');

    if(document.documentElement.clientWidth < 576) {
        pureSlider('.advantages__slides', 'dots', 'classic', false, false, true);
        pureSlider('.delivery__icons', 'dots', 'classic', false, false, true);
    }
    window.addEventListener('resize', () => {
        if(document.documentElement.clientWidth < 576) {
            pureSlider('.advantages__slides', 'dots', 'classic', false, false, true);
            pureSlider('.delivery__icons', 'dots', 'classic', false, false, true);
        }
    })
    const promotionItem = document.querySelectorAll('.promotions-item');
    if(promotionItem[0]) {
        
        timer('.promotions-item__timer', '2021-12-12', promotionItem[0]);
        timer('.promotions-item__timer', '2021-9-12', promotionItem[1]);
        
    }
    const rating = document.querySelectorAll('.rating__input');
    if(rating[0]) {
        rating.forEach(item => {
            item.addEventListener('click', (e) => {
                if(e.target.value == 1){
                    item.parentElement.nextElementSibling.innerHTML = '1 балл'
                }
                if(e.target.value == 2){
                    item.parentElement.nextElementSibling.innerHTML = '2 балла'
                }
                if(e.target.value == 3){
                    item.parentElement.nextElementSibling.innerHTML = '3 балла'
                }
                if(e.target.value == 4){
                    item.parentElement.nextElementSibling.innerHTML = '4 балла'
                }
                if(e.target.value == 5){
                    item.parentElement.nextElementSibling.innerHTML = '5 баллов'
                }
            })
        })
    }
})

