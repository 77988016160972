
const amount= () => {
    const order = document.querySelectorAll('.order-calc');
    order.forEach(item => {
        const amountBlock = item.querySelectorAll('#amount');
        const radios = item.querySelectorAll('.express-input');
        const deliveryPrice = item.querySelector('.flex.delivery-price .price span');
        if(deliveryPrice) {

            radios.forEach(i => {
                i.addEventListener('click', () => {
                    calcDelivery();
                    calcOrder();
                })
            })
        }

        
        function calcDelivery() {
            
            for(let i=0; i<radios.length; i++) {
                if(radios[i].checked) {
                    let label = radios[i].nextElementSibling.children[0];
                    deliveryPrice.textContent = label.textContent.replace(/[^\d.-]/g, '')
                    break;
                }
            }
        }
        


        function calcOrder() {
            const total = item.querySelector('.checkout-cart-info__price span');
                
            
            if(total) {
                const cost =    total.parentElement.parentElement.parentElement.querySelectorAll('.price span');
                var sum = 0;
                for(let i =0; i < cost.length; i++) {
                    const total = item.querySelector('.checkout-cart-info__price span');
                    sum += +cost[i].textContent.replace(/[^\d.-]/g, '');
                    total.textContent = +sum;
                }
                let deliveryPrice = item.querySelectorAll('.checkout-cart-info__price');
                item
                const totalPrice = item.querySelector('.checkout-cart-total__price span');
                
                deliveryPrice.forEach(item => {
                    if(item.classList.contains('free')) {
                        totalPrice.textContent = (+total.textContent).toFixed(2);
                    } else {
                        totalPrice.textContent = (+total.textContent + +item.textContent).toFixed(2);

                    }
                })
                
                // console.log(total.textContent);
            }
            
        }
        if(radios) {
            calcOrder();
        }
        

        if(amountBlock) {
            amountBlock.forEach(amount => {
                var standardPrice;
                if(!amount.classList.contains('no-price')) {
                    var standardPrice = amount.previousElementSibling.children[0].textContent.replace(/\s/g, '');
                }
                function calcItemPrice() {
                    
                    let newPrice = +standardPrice * +amount.children[1].value;
                    amount.previousElementSibling.children[0].textContent = newPrice.toFixed(2);
                    

                }
                if (!amount.classList.contains('no-price')) {
                    calcItemPrice();
                }

                
                


                function addVal() {
                    amount.children[1].value = +amount.children[1].value + 1;
                    if (amount.children[1].value == 99 ) {
                        amount.children[2].removeEventListener('click', addVal)
                    } else {
                        amount.children[0].addEventListener('click', decrVal)
                        amount.children[2].addEventListener('click', addVal)
                    }
                    amount.children[0].addEventListener('click', decrVal)
                    if (!amount.classList.contains('no-price')) { 
                        calcItemPrice();
                        if(radios) {
                            calcOrder();
                        }
                    }
                    
                    
                }   
                amount.children[2].addEventListener('click', addVal)
            
            
                function decrVal() {
                    if (amount.children[1].value < 3 ) {
                        amount.children[0].removeEventListener('click', decrVal)
                    } else {
                        amount.children[2].addEventListener('click', addVal)
                        amount.children[0].addEventListener('click', decrVal);
                    }
                    amount.children[1].value = +amount.children[1].value - 1;
                    if (!amount.classList.contains('no-price')) { 
                        calcItemPrice();
                        if(radios) {
                            calcOrder();
                        }
                    }
                }   
                
        
            });
        }
        
    })
    
}

export default amount