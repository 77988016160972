const modal = () => {
    const cart = document.querySelector('#cart'),
          favBtn = document.querySelector('.header-r__favorite'),
          overflow =document.querySelector('.overflow'),
          popupClose =document.querySelectorAll('.popup__close'),
          orderCall = document.querySelectorAll('#order-call'),
          review = document.querySelector('#review'),
          overflowReviews = document.querySelector('.overflow_reviews'),
          hamburger = document.querySelector('.hamburger');
          
    if(cart) {
        const favMenu = document.querySelector('#favorite');
        const cartMenu = document.querySelector('#cartPopup');
        const headerMobile = document.querySelector('.header__mobile');
        const close = document.querySelectorAll('.cart__close');
        close.forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                closeAll()
            });
        });

        function closeAll() {
            favMenu.classList.remove('cart_active');
            headerMobile.classList.remove('header__mobile_active');
            cartMenu.classList.remove('cart_active');
            overflow.style.opacity = 0;
            overflow.style.visibility = 'hidden';
            document.body.style.overflow = 'visible';
            document.body.style.marginRight = 0;
            if(overflowReviews) {
                overflowReviews.style.opacity = 0;
                overflowReviews.style.visibility = 'hidden';
            }
        }
        cart.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.style.overflow = 'hidden';
            document.body.style.marginRight = `${scroll}px`;
            favMenu.classList.remove('cart_active');

            headerMobile.classList.remove('header__mobile_active');
            cartMenu.classList.add('cart_active');
        })
        favBtn.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.style.overflow = 'hidden';
            document.body.style.marginRight = `${scroll}px`;
            cartMenu.classList.remove('cart_active');
            headerMobile.classList.remove('header__mobile_active');
            favMenu.classList.add('cart_active');
        })
        hamburger.addEventListener('click', () => {
            cartMenu.classList.remove('cart_active');
            document.body.style.overflow = 'hidden';
            document.body.style.marginRight = `${scroll}px`;
            favMenu.classList.remove('cart_active');
            headerMobile.classList.add('header__mobile_active');

        })
        document.addEventListener('keydown', (e) => {
            if(e.key === 'Escape') {
                closeAll();
            }
        })
        document.addEventListener('click', (e) => {
            function isDescendant(parent, child) {
                var node = child.parentNode;
                while (node != null) {
                    if (node == parent) {
                        return false;
                    }
                    node = node.parentNode;
                }
                return true;
            }
            if(isDescendant(favMenu, e.target) && !e.target.classList.contains('header-r__favorite') && isDescendant(favBtn, e.target)) {
                if(document.body.style.overflow === 'hidden' && favMenu.classList.contains('cart_active')) {
                    document.body.style.overflow = 'visible';
                    document.body.style.marginRight = 0;
                }
                favMenu.classList.remove('cart_active');

            }
            if(isDescendant(cartMenu, e.target) && !e.target.classList.contains('header-r__cart') && isDescendant(cart, e.target)) {
                if(document.body.style.overflow === 'hidden' && cartMenu.classList.contains('cart_active')) {
                    document.body.style.overflow = 'visible';
                    document.body.style.marginRight = 0;
                }
                cartMenu.classList.remove('cart_active');
                
            }
            if(isDescendant(headerMobile, e.target) && !e.target.classList.contains('hamburger') && isDescendant(hamburger, e.target)) {
                if(document.body.style.overflow === 'hidden' && headerMobile.classList.contains('header__mobile_active')) {
                    document.body.style.overflow = 'visible';
                    document.body.style.marginRight = 0;
                }
                headerMobile.classList.remove('header__mobile_active');

            }
        })
        orderCall.forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                closeAll();
                overflow.style.opacity = 1;
                overflow.style.visibility = 'visible';
                document.body.style.overflow = 'hidden';
                document.body.style.marginRight = `${scroll}px`;
                
            })
        })
        popupClose.forEach(popupClose => {
            popupClose.addEventListener('click', (e) => {
                e.preventDefault();
                closeAll()
    
            })
        })
        
        overflow.addEventListener('click', (e) => {
            if(e.target.classList.contains('overflow')) {
                closeAll();

            }
        })
        if(overflowReviews) {
            overflowReviews.addEventListener('click', (e) => {
                if(e.target.classList.contains('overflow_reviews')) {
                    closeAll();
    
                }
            })
        }
        
        const scroll = calcScroll();

        function calcScroll() {
            let div = document.createElement('div');
            div.style.width = '50px';
            div.style.height = '50px';
            div.style.overflowY = 'scroll';
            div.style.visibility = 'hidden';

            document.body.appendChild(div);
            let scrollWidth = div.offsetWidth - div.clientWidth;
            div.remove();
            return scrollWidth;
        }
        if(review) {
            review.addEventListener('click', (e) => {
                e.preventDefault();
                closeAll();
                overflowReviews.style.opacity = 1;
                overflowReviews.style.visibility = 'visible';
                document.body.style.overflow = 'hidden';
                document.body.style.marginRight = `${scroll}px`;
            })
        }

    }
}

export default modal;