function scroll () {


    const anchors = document.querySelectorAll('a[href*="#top"]'),
          top = document.querySelector('.top');


    document.addEventListener('scroll', () => {
       if(window.pageYOffset > 400) {
            top.classList.add('top_active');
        } else {
            top.classList.remove('top_active');
        }
    })
    for (let anchor of anchors) {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        
        const blockID = anchor.getAttribute('href').substr(1)
        
        document.getElementById(blockID).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      })
    }
}

export default scroll;