const validation = () => {
    const forms = document.querySelectorAll('form');

    forms.forEach(form => {
        const   names = form.querySelectorAll('.name-input'),
                phone = form.querySelectorAll('.phone-input'), 
                messages = form.querySelectorAll('.message-input'),
                emails = form.querySelectorAll('.email-input'),
                country = form.querySelectorAll('.country-input'),
                address = form.querySelectorAll('.address-input'),
                state = form.querySelectorAll('.state-input'),
                city = form.querySelectorAll('.city-input'),
                postal = form.querySelectorAll('.postal-input'),
                delivery = form.querySelectorAll('.express-input'),
                payment = form.querySelectorAll('.payment-input'),
                price = form.querySelectorAll('.price-input'),
                phoneRegEx = new RegExp(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){9,14}(\s*)?$/),
                emailRegEx = new RegExp(/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/),
                clean = form.querySelector('.btn_clean');
        
        
        let errorName = false,
            errorMessage = false,
            errorPhone = false,
            errorEmail = false,
            errorAddress = false,
            errorCountry = false,
            errorState = false,
            errorCity = false,
            errorPostal = false,
            errorDelivery = false,
            errorPrice = false,
            errorPayment = false;


        let input = form.querySelectorAll('input');

        if(clean) {
            clean.addEventListener('click', () => {
                
                input.forEach(item => {
                    item.value ='';
                    if(item.type === 'checkbox' || item.type === 'radio') {
                        item.checked = false;
                    }
                })
            })
        }

        function removeError(item) {
            if(item.value.trim().length > 0) {
                item.classList.remove('input_error');
                item.parentElement.classList.remove('input_error');
                let span = item.parentElement.querySelector('.span_error');
                if (span) {
                    span.remove();
                }
            }
        }


        function check(attr, regEx, type, char) {
            if(attr) {
                attr.forEach(i => {
                    i.addEventListener('input', ()=> {
                        if( type === 'regEx' && regEx.test(i.value)) {
                            removeError(i);
                            if(attr === phone) {
                                return errorPhone = false
                            }
                            if(attr === emails) {
                                return errorEmail = false
                            }
                        } 
                        if(type === 'length') {
                            if(i.value.length >= char) {
                                removeError(i);
                                if(attr === messages) {
                                    return errorMessage = false
                                }
                                if(attr === names) {
                                    return errorName = false
                                }
                                if(attr === address) {
                                    return errorAddress = false;
                                }
                                if(attr === price) {
                                    return errorPrice = false;
                                }
                            }
                        }
                    })
                    if(type === 'select') {
                        i.nextElementSibling.nextElementSibling.addEventListener('click', () => {
                            if(i.value > 0) {
                                removeError(i);
                                if(attr === country) {
                                    return errorCountry = false;
                                }
                                if(attr === city) {
                                    return errorCity = false;
                                }
                                if(attr === postal) {
                                    return errorPostal = false;
                                }
                                if(attr === state) {
                                    return errorState = false;
                                }
                            }
                        })
                    }
                    if(type === 'radio') {
                        i.addEventListener('change', () => {
                            var radios = i.parentElement.parentElement.querySelectorAll('input[type="radio"]:checked');
                            var value = radios.length>0? radios[0].value: null;

                            if(value) {
                                i.parentElement.parentElement.classList.remove('input_error');
                                let span = i.parentElement.parentElement.querySelector('.span_error');
                                if (span) {
                                    span.remove();
                                }
                                if(attr === delivery) {
                                    return errorDelivery = false;
                                }
                                if(attr = payment) {
                                    return errorPayment = false;
                                }
                            }
                        })
                    }
                })
            }
        }
    
        check(emails, emailRegEx, 'regEx');
        check(phone, phoneRegEx, 'regEx');
        check(messages, '', 'length', 10);
        check(names, '', 'length', 2);
        check(address, '', 'length', 2);
        check(country, '', 'select');
        check(city, '', 'select');
        check(state, '', 'select');
        check(postal, '', 'select');
        check(delivery, '', 'radio');
        check(payment, '', 'radio');
        check(price, '', 'length', 4)
        

        function showError(item, message, type) {

            let error = document.createElement('span');
            error.setAttribute('class', 'span_error');

            if(type === 'select') {
                item.parentElement.classList.add('input_error');
                item.parentElement.style.boxSizing = 'content-box';
                item.parentElement.insertAdjacentElement('beforeend', error)
                
            } else if(type === 'radio') {
                if(!item.parentElement.parentElement.querySelector('.span_error')) {
                    item.parentElement.parentElement.classList.add('input_error');
                    item.parentElement.parentElement.insertAdjacentElement('beforeend', error)
                }
                
            }else {
                item.classList.add('input_error');
                item.parentElement.insertAdjacentElement('beforeend', error)
            }
            error.innerHTML = message
        }
        const btn = form.querySelector('button');

            if(btn) {
                btn.addEventListener('click', () => {

                    function errorMessageFunction(attr, type, char, message, regEx) {
                        if (attr) {
                            attr.forEach(item => {
                                let error = item.parentElement.querySelector('.span_error');
                                if (type === 'length') {
                                    if (item.value.trim().length < char && !error) {
                                        showError(item, message);
                                        if (attr === names) {
                                            return errorName = true
                                        }
                                        if (attr === messages) {
                                            return errorMessage = true
                                        }
                                        if (attr === address) {
                                            return errorAddress = true;
                                        }
                                        if(attr === price) {
                                            return errorPrice = true;
                                        }
                                    }
                                }
                                if (type === 'regex') {
                                    if (!regEx.test(item.value) && !error) {
                                        showError(item, message)
                                        if (attr === phone) {
                                            return errorPhone = true;
                                        }
                                        if (attr === emails) {
                                            return errorEmail = true;
                                        }

                                    }
                                }
                                if (type === 'select') {
                                    if (item.value < 1 && !error) {
                                        showError(item, message, type);
                                        if (attr === country) {
                                            return errorCountry = true
                                        }
                                        if (attr === city) {
                                            return errorCity = true
                                        }
                                        if (attr === state) {
                                            return errorState = true
                                        }
                                        if (attr === postal) {
                                            return errorPostal = true
                                        }
                                    }
                                }
                                if (type === 'radio') {
                                    let error = item.querySelector('.span_error');
                                    var radios = item.parentElement.parentElement.querySelectorAll('input[type="radio"]:checked');
                                    var value = radios.length > 0 ? radios[0].value : null;
                                    if (!value && !error) {
                                        showError(item, message, type);
                                        if (attr === delivery) {
                                            return errorDelivery = true
                                        }
                                        if (attr === payment) {
                                            return errorPayment = true
                                        }
                                    }
                                }

                            })
                        }
                    }
                errorMessageFunction(names, 'length', 2, `Name is not correct (minimum length - 2 symbols)`);
                errorMessageFunction(messages, 'length', 10, `Please describe your problem <br>(minimum length - 10 symbols)`);
                errorMessageFunction(phone, 'regex', 0, `Enter the correct phone number`, phoneRegEx);
                errorMessageFunction(emails, 'regex', 0, `Enter the correct email`, emailRegEx);
                errorMessageFunction(address, 'length' , 2, `Enter correct address`);
                errorMessageFunction(country, 'select' , 0, `Choose a country`);
                errorMessageFunction(city, 'select' , 0, `Choose a city`);
                errorMessageFunction(postal, 'select' , 0, `Choose a postal`);
                errorMessageFunction(state, 'select' , 0, `Choose a state`);
                errorMessageFunction(delivery, 'radio', 0, 'Choose a delivery option');
                errorMessageFunction(payment, 'radio', 0, 'Choose a payment method');
                errorMessageFunction(price, 'length', 5, 'Пожалуйста, укажите примерный бюджет. Например: 300 руб')
            
            })
        }

        form.addEventListener('submit', (e) => {
            e.preventDefault();
            input.forEach(input => {
                input.classList.remove('editable');
            })
            if(!errorName && !errorPrice && !errorMessage && !errorPhone && !errorEmail && !errorAddress && !errorCountry && !errorState && !errorCity && !errorPostal && !errorDelivery && !errorPayment) {
                console.log(true)
                
            }
            

        })



        const same = form.querySelector('#same'); 

        if (same) {
            same.addEventListener('click', () => {
                if(same.checked) {
                    same.nextElementSibling.nextElementSibling.style.display = 'none';
                } else {
                    same.nextElementSibling.nextElementSibling.style.display = 'block';
                }
            })

        }        
    })


}

export default validation;