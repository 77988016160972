const dropdown = () => {
    const dropdowns = document.querySelectorAll('.dropdown');
    if(dropdowns) {
        dropdowns.forEach(dropdown => {
            dropdown.addEventListener('click', () => {
                if(dropdown.classList.contains('rotate')){
                    dropdown.nextElementSibling.classList.remove('active');
                    dropdown.nextElementSibling.classList.add('hidden');
                    dropdown.classList.remove('rotate');

                } else {
                    dropdown.nextElementSibling.classList.add('active');
                    dropdown.nextElementSibling.classList.remove('hidden');
                    dropdown.classList.add('rotate');
                }
                
                
            })
        })
    }
}
export default dropdown;