const openSearch = () => {
    const open = document.querySelector('.header-r__search');
    if(open) {
        open.addEventListener('click', (e) => {
            e.preventDefault();
            console.log(open.previousElementSibling)
            open.previousElementSibling.style.opacity = 1;
            open.previousElementSibling.style.visibility = 'visible';
            open.style.display = 'none';
        })
        const close = document.querySelector('.close__icon');
        close.addEventListener('click', (e) => {
            e.preventDefault();
            open.style.display = 'block';
            close.parentElement.style.opacity = 0;
            close.parentElement.style.visibility = 'hidden';
        })
    }
}

export default openSearch;